import {createStore} from 'vuex'

export default createStore({
    state: {
        user: {},
        items: [],
    },
    mutations: {
        NEW_ITEM(state, item) {
            state.items.push(item)
        },
        EDIT_ITEM(state, payload) {
            state.items[payload.index] = payload.item;
        },
        DELETE_ITEM(state, index) {
            state.items.splice(index, 1);
        },
        LOGIN(state, user){
            state.user = user;
        }
    },
    actions: {
        saveItem({commit, state}, item) {
            commit('NEW_ITEM', item)
        },
        editItem({commit, state}, payload){
            commit('EDIT_ITEM', payload)
        },
        deleteItem({commit, state}, index){
            commit('DELETE_ITEM', index)
        },
        login({commit, state}, user){
            commit('LOGIN', user)
        }
    },
    getters:{
        items(state){
          return state.items;
        },
        user(state){
            return state.user;
        },
        familiyMembersCount(state){
            let counter = 0;

            for (let i = 0; i < state.items.length; i++) {
                if(state.items[i].type == 'application'){

                    if(state.items[i].participant.is_familiy){
                        counter++;
                    }
                }
            }
            return counter

        }
    },
    modules: {}
})
