<template>
  <div class="steps">
    <div class="container">
      <div class="steps__counter">
        <p>01/03<br>Privatstunde</p>
      </div>
      <h1>Welche Disziplin möchten Sie buchen?</h1>

      <div class="form form--narrow mx-5 xl:mx-auto">
        <div class="grid grid-cols-1 gap-4">
          <div class="form-group">
            <label for="discipline">Disziplin</label>
            <select id="discipline" class="form-select" :class="fieldErrorDiscipline ? 'field__error' : ''" v-model="discipline">
              <option disabled selected value="0">Bitte wählen</option>
              <option value="Ski alpin">Ski alpin</option>
              <option value="Snowboard">Snowboard</option>
              <option value="Langlauf (klassisch)">Langlauf (klassisch)</option>
              <option value="Langlauf (skating)">Langlauf (skating)</option>
            </select>
          </div>
        </div>
        <div class="buttons flex flex-row mx-auto justify-end items-center">
          <div :to="{ name : 'Start'}" class="btn btn-bordered flex justify-center items-center mr-5" @click.native="checkCourseData()">
            <img src="../assets/icons/bx-right-arrow-alt.svg" class="arrow arrow-left">
          </div>
          <div class="btn btn-blue flex justify-center items-center" v-on:click="saveData()">
            <img src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import de from "date-fns/locale/de";

export default {
  name: 'PrivateLessonStep01',
  props: {
    editIndex: String,
    repeatBooking: Object
  },
  data() {
    return {
      id: null,
      discipline: 0,
      fieldErrorDiscipline: false,
    }
  },
  created() {
    if(this.editIndex){
      const item = this.$store.getters.items[this.editIndex];
      this.discipline = item.course_type;
    }
  },
  methods: {
    saveData() {
      (this.discipline === '' || this.discipline == 0) ? this.fieldErrorDiscipline = true : this.fieldErrorDiscipline = false;

      if (!this.fieldErrorDiscipline) {
        let itemToSave = this.$store.getters.items[this.editIndex];
        this.$store.dispatch('editItem', {
          index: this.editIndex,
          item:{
            type: "private_application",
            course_type: this.discipline,
            participants: itemToSave.participants,
            dates: itemToSave.dates,
            price: itemToSave.price
          }
        })
        this.$router.push({
          name: 'PrivateLessonStep02',
          params: {discipline: this.discipline, editIndex: this.editIndex}
        })
      }
    },
    checkCourseData(){
      let itemToSave = this.$store.getters.items[this.editIndex];

      if(itemToSave.dates.length == 0 || itemToSave.participants.length == 0){
        let conf = confirm("Wollen Sie die Buchung löschen?");
        if(conf){
          this.$store.dispatch('deleteItem', this.editIndex)
          if(this.$store.getters.items.length > 0){
            this.$router.push({ path: 'zusammenfassung' })
          } else {
            this.$router.push({ path: '/' })
          }
          return
        } else {
          return
        }
      }
      if(itemToSave.participants.length > 0){
        for (let i = 0; i < itemToSave.participants.length; i++) {
          if((itemToSave.participants[i].birthday == '' && itemToSave.participants[i].birthday == null) || (itemToSave.participants[i].first_name == '' || itemToSave.participants[i].last_name == '') || itemToSave.participants[i].skill == null){
            let conf = confirm("Wollen Sie die Buchung löschen?");
            if(conf){
              this.$store.dispatch('deleteItem', this.editIndex)
              if(this.$store.getters.items.length > 0){
                this.$router.push({ path: 'zusammenfassung' })
              } else {
                this.$router.push({ path: '/' })
              }
              return
            } else {
              return
            }
          }
        }
      }
      this.$router.push({ path: 'zusammenfassung' })

    }
  }
}
</script>
