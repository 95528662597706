<template>
  <div class="steps">
    <div class="container">
      <h1>Ihre Übersicht</h1>

      <div class="price__container-top flex justify-between  xl:hidden">
        <div class="price">
          {{ totalPrice }}
        </div>
        <div class="book">
          <div class="btn btn--finalize" v-on:click="finalize">Buchen und Bezahlen</div>
        </div>
      </div>
      <div class="card__container grid grid-cols-1 gap-4 xl:grid-cols-3 xl:gap-8">
        <div class="flex align-center" v-for="(item, index) in this.items">

          <div class="card card-full-height" v-if="item.type === 'application'">
            <Card_Application :item="item" :index="parseInt(index)"></Card_Application>
          </div>
          <div class="card card-full-height" v-if="item.type === 'private_application'">
            <Card_PrivateApplication :item="item" :index="parseInt(index)"></Card_PrivateApplication>
          </div>

        </div>
        <div class="flex items-center">
          <div class="card">
            <div class="card-header flex justify-center">
              <img src="../assets/images/teaser-kurs-buchen-default.svg" class="teaser--large">
            </div>
            <div class="card-body">
              <router-link :to="{ name: 'Start'}" class="link--text flex justify-center flex-col items-center">
                Zum Kurs anmelden oder Privatstunde buchen<br><img src="../assets/icons/bx-plus-circle.svg"
                                                                   class="block mt-2">
              </router-link>
            </div>
          </div>

        </div>

      </div>
      <div class="price__container flex justify-between">
        <div class="price">
          {{ totalPrice }}
        </div>
        <div class="book">
          <div class="btn btn--finalize" v-on:click="finalize">Buchen und Bezahlen</div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Card_Application from "@/components/Card_Application";
import Card_PrivateApplication from "@/components/Card_PrivateApplication";
import moment from "moment";

export default {
  name: 'CourseSummary',
  components: {
    Card_Application,
    Card_PrivateApplication
  },
  computed: {
    ...mapState({
      items: 'items',
    }),
    totalPrice() {
      let p = 0
      if (this.items) {
        this.items.forEach(function (item) {

          p += parseFloat(item.price)
          if (item.liftticket_price !== undefined) {
            p += parseFloat(item.liftticket_price)
          }
        })
      }
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(p);
    }
  },
  methods: {

    formatDate(dateString) {
      if (!dateString) {
        return 0
      }
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('de_DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

      }).format(date);
    },
    timeToHours(start, end) {
      return end - start;
    },
    finalize() {
      let items = this.$store.getters.items;
      items = JSON.parse(JSON.stringify(items));

      for (let i = 0; i < items.length; i++) {

        if (items[i].type === 'private_application') {
          for (let j = 0; (j < items[i].participants.length); j++) {
            items[i].participants[j].birthday = moment(items[i].participants[j].birthday).format('YYYY-MM-DD');
          }
          for (let j = 0; (j < items[i].dates.length); j++) {
            items[i].dates[j].date = moment(items[i].dates[j].date).format('YYYY-MM-DD');
          }
        } else if (items[i].type === 'application') {
          items[i].participant.birthday = moment(items[i].participant.birthday).format('YYYY-MM-DD');
        }
      }

      let message = {
        event: "ITEMS_ADD",
        data: {
          items: items
        }
      }

      window.parent.postMessage(message, process.env.VUE_APP_ROOT_API)
    },
    editItem(type, index) {
      if (type === 'private') {
        this.$router.push({
          name: 'PrivateLessonStep01'
        })
      } else if (type === 'application') {
        this.$router.push({
          name: 'CourseStep01'
        })
      }

    }
  }
}
</script>
