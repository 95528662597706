<template>
  <div class="start">
    <div class="container px-5 xl:px-0">
      <h1>Ihre Übersicht</h1>
      <h2 class="card__container headline__blue">Ihre aktuellen Buchungen</h2>
      <div class="grid grid-cols-1 gap-4 xl:grid-cols-3 xl:gap-8 mt-50">
        <div class="flex align-center justify-center" v-for="(item, index) in this.items">

          <div class="card card-full-height" v-if="item.type == 'application'">
            <Card_Application :item="item" :index="parseInt(index)"></Card_Application>
          </div>
          <div class="card card-full-height" v-if="item.type == 'private_application'">
            <Card_PrivateApplication :item="item" :index="parseInt(index)"></Card_PrivateApplication>
          </div>
        </div>
        <div class="flex items-center">
          <div class="card">
            <div class="card-header flex justify-center">
              <img src="../assets/images/teaser-kurs-buchen-default.svg" class="teaser--large">
            </div>
            <div class="card-body">
              <router-link :to="{ name: 'Start'}" class="link--text flex justify-center flex-col items-center">
                Zum Kurs anmelden oder Privatstunde buchen<br><img src="../assets/icons/bx-plus-circle.svg" class="block mt-2">
              </router-link>
            </div>
          </div>

        </div>

      </div>


    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import Card_Application from "@/components/Card_Application";
import Card_PrivateApplication from "@/components/Card_PrivateApplication";

export default {
  name: 'Dashboard',
  components: {
    Card_Application,
    Card_PrivateApplication
  },
  computed: {
    ...mapState({
      items: 'items',
      user: 'user'
    }),
  },
  methods: {
    formatDate(dateString) {
      if(!dateString){
        return 0
      }
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

      }).format(date);
    },
    timeToHours(start, end){
      return end - start;
    },
  }
}
</script>

<style>

</style>
