<template>
  <div class="tooltip">
    <div class="tooltip-icon">
      <img src="@/assets/icons/bxs-info-circle.svg">
    </div>
    <div class="tooltip-wrapper">
      <div class="tooltip-inner relative">
        <div class="tooltip-arrow"></div>
        <div class="tooltip-content">
          {{ this.text}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>


export default {
  name: 'Tooltip',
  components: {},
  props: {
    text: String
  },
}
</script>
