<template>
  <div class="card-close" v-on:click="deleteApplication"></div>
  <div class="card-header flex justify-center">
    <img src="../assets/images/teilnehmer-skikurs_02.svg" class="teaser">
  </div>
  <div class="card-body" v-if="item">
    <h4 class="font-bold">{{ item.participant.first_name }} {{ item.participant.last_name }}</h4>
    <table>
      <dl>
        <dt>Geburtstag:</dt>
        <dd>{{ formatDate(item.participant.birthday) }}</dd>
        <dt>Familie:</dt>
        <dd>{{ item.participant.is_familiy ? 'Ja' : 'Nein' }}</dd>
        <dt>Kurs:</dt>
        <dd><strong>{{ item.course_type.name }}</strong></dd>
        <dt>Können:</dt>
        <dd>{{ item.skill.name }}</dd>
        <dt>Zeitraum:</dt>
        <dd>{{ formatDate(item.course_start.date) }} - {{ formatDate(item.course_end.date) }}</dd>
        <dt>Liftticket:</dt>
        <dd class="flex">{{item.is_liftticket_selected ? 'Ja' : 'Nein'}}</dd>
        <dt class="price">Preis:</dt>
        <dd class="price"><strong>{{ priceToEuro(item.price + item.liftticket_price) }}</strong></dd>
      </dl>
    </table>

    <div class="flex justify-center" >
      <a  v-on:click="editItem(index)" class="link--text cursor-pointer">Kursteilnahme bearbeiten</a>
    </div>
  </div>
</template>

<script>
import de from "date-fns/locale/de";
import {mapState} from "vuex";

export default {
  name: 'Card_PrivateApplication',
  components: {},
  props: {
    item: Object,
    index: Number
  },

  methods: {
    priceToEuro(price){
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(price);
    },
    formatDate(dateString) {
      if(!dateString){
        return 0
      }
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('de-DE',{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

      }).format(date);
    },
    timeToHours(start, end){
      return end - start;
    },
    editItem(index){
      this.$router.push({
        name: 'CourseStep01',
        params: {editIndex: index}
      })

    },
    deleteApplication(){
      let conf = confirm("Wollen Sie die Buchung löschen?");
      if(conf){
        this.$store.dispatch('deleteItem', this.index)

        let items = this.$store.getters.items;
        let familyMembers = this.$store.getters.familiyMembersCount;

        for (let i = 0; i < items.length; i++) {
          if(items[i].type == 'application') {

            let api = process.env.VUE_APP_COURSE_API + '/course_prices/course_start/' + items[i].course_start.id + '/course_end/' + items[i].course_end.id + '/course_skill/' + items[i].skill.id + '/familiy_members_count/' + familyMembers;

            this.axios.get(api).then((response) => {
              items[i].price = parseFloat(response.data.price);
            })
          }
        }
      }
    }
  }
}
</script>
