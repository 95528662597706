<template>
  <div class="steps">
    <div class="container">
      <div class="steps__counter">
        <p>01/02<br>Kurs</p>
      </div>
      <h1>Wer möchte teilnehmen?</h1>

      <div class="form mx-5 xl:mx-auto">
        <div class="block pt-60 xl:hidden" v-if="user && user.participants && user.participants.length > 0">
          <h2 class="mt-90 headline__blue">Bereits registrierte Teilnehmer erneut anmelden</h2>
          <div class="mt-8 mb-60 grid grid-cols-1 gap-4 xl:grid-cols-2 xl:gap-8">

            <div class="flex" v-for="item in user.participants">

              <div class="card">
                <div class="card-header flex justify-center">
                  <img src="../assets/images/teilnehmer-skikurs.svg" class="teaser">
                </div>
                <div class="card-body">

                  <h4 class="font-bold">{{ item.first_name }} {{ item.last_name }}</h4>
                  <table class="w-full">
                    <dl>
                      <dt>Geburtstag:</dt>
                      <dd>{{ formatDate(item.birthday) }}</dd>
                    </dl>
                  </table>

                  <div class="flex justify-center mt-2">
                    <a v-on:click="inputUserData(item)" class="link--text btn__blue cursor-pointer" >erneut anmelden</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 xl:grid-cols-2 ">
          <div class="form-group">
            <label for="lastName">Nachname</label>
            <input id="lastName" v-model="last_name" :disabled="isAlreadyRegistered" :class="fieldErrorLastName ? 'field__error' : ''" type="text">
            <span class="field__error--label" v-if="fieldErrorLastName">Bitte ausfüllen</span>
          </div>
          <div class="form-group">
            <label for="firstName">Vorname</label>
            <input id="firstName" v-model="first_name" :disabled="isAlreadyRegistered" :class="fieldErrorFirstName ? 'field__error' : ''" type="text">
            <span class="field__error--label" v-if="fieldErrorFirstName">Bitte ausfüllen</span>
          </div>
          <div class="form-group">
            <label for="birthday">Geburtstag</label>
            <Datepicker id="birthday" :enableTimePicker="false" textInput v-model="birthday" autoApply :format="'dd.MM.yyyy'" :maxDate="datepickerUpperLimit" locale="de" cancelText="" selectText="" :class="fieldErrorBirthday ? 'field__error' : ''">
              <template #clear-icon></template>
              <template #input-icon>
<!--                <img class="input-slot-image" src="../assets/icons/bx-calendar.svg"/>-->
              </template>
            </Datepicker>

            <span class="field__error--label" v-if="fieldErrorBirthday">Bitte ausfüllen</span>
          </div>
          <div class="form-group">
            <label>Familie</label>
            <tooltip text="Um den Rabatt zu erhalten, müssen mindestens 3 Teilnehmer ein Familienmitglied sein. <br><br>
                    Unter Familienmitglied verstehen wir Ehepartner, eheähnliche Lebensgemeinschaften, sowie die dazugehörigen Kinder, die in einem gemeinsamen Haushalt leben. In Zweifelsfällen ist bei der Abholung der Nachweis zu erbringen"></tooltip>

            <select v-model="is_familiy">
              <option value="true">ja</option>
              <option value="false">nein</option>
            </select>
          </div>
        </div>
        <div class="buttons flex flex-row mx-auto justify-end items-center ">
          <div  class="btn btn-bordered flex justify-center items-center" @click.native="checkCourseData($event)">
            <img src="../assets/icons/bx-right-arrow-alt.svg" class="arrow arrow-left">
          </div>
          <div class="btn btn-blue flex justify-center items-center" v-on:click="saveData()">
            <img src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white">
          </div>
        </div>
        <div class="hidden xl:block" v-if="user && user.participants && user.participants.length > 0">
          <h2 class="mt-90 headline__blue">Bereits registrierte Teilnehmer erneut anmelden</h2>
          <div class="mt-8 mb-60 grid grid-cols-1 gap-4 xl:grid-cols-2 xl:gap-8">

            <div class="flex" v-for="item in user.participants">

              <div class="card">
                <div class="card-header flex justify-center">
                  <img src="../assets/images/teilnehmer-skikurs.svg" class="teaser">
                </div>
                <div class="card-body">

                  <h4 class="font-bold">{{ item.first_name }} {{ item.last_name }}</h4>
                  <table class="w-full">
                    <dl>
                      <dt>Geburtstag:</dt>
                      <dd>{{ formatDate(item.birthday) }}</dd>
                    </dl>
                  </table>

                  <div class="flex justify-center mt-2">
                    <a v-on:click="inputUserData(item)" class="link--text btn__blue cursor-pointer" >erneut anmelden</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import de from "date-fns/locale/de";
import {mapState} from "vuex";
import Tooltip from "../components/Tooltip";

export default {
  name: 'CourseStep01',
  props: {
    editIndex: String
  },
  components: {
    Tooltip
  },
  data() {
    return {
      de: de,
      id: null,
      last_name: '',
      first_name: '',
      birthday: null,
      is_familiy: false,

      isAlreadyRegistered: false,

      datepickerUpperLimit: new Date(),

      fieldErrorLastName: false,
      fieldErrorFirstName: false,
      fieldErrorBirthday: false,
    }
  },

  computed: {
    ...mapState({
      items: 'items',
      user: 'user'
    }),
  },
  created() {
    if(this.editIndex){
      const item = this.$store.getters.items[this.editIndex];

      this.last_name = item.participant.last_name;
      this.first_name = item.participant.first_name;
      if(item.participant.birthday){
        let bday = new Date(item.participant.birthday);
        this.birthday = bday;
      }

      this.is_familiy = item.participant.is_familiy;

    }
  },
  methods: {
    saveData() {
      this.last_name === '' ? this.fieldErrorLastName = true : this.fieldErrorLastName = false;
      this.first_name === '' ? this.fieldErrorFirstName = true : this.fieldErrorFirstName = false;
      (this.birthday === '' || this.birthday == null) ? this.fieldErrorBirthday = true : this.fieldErrorBirthday = false;

      if (!this.fieldErrorLastName && !this.fieldErrorFirstName && !this.fieldErrorBirthday) {
        let itemToSave = this.$store.getters.items[this.editIndex];
        let bday = this.formatDate(new Date(this.birthday));
        bday = bday.split(".");
        let birthDay = bday[2] + '-' + bday[1] + '-'+ bday[0];


        this.$store.dispatch('editItem', {
          index: this.editIndex,
          item: {
            type: "application",
            participant: {
              id: 0,
              first_name: this.first_name,
              last_name: this.last_name,
              is_familiy: this.is_familiy,
              birthday: birthDay,
            },
            course_start: itemToSave.course_start,
            course_end: itemToSave.course_end,

            is_liftticket_selected: Boolean(itemToSave.is_liftticket_selected),
            skill: itemToSave.skill,
            course_type: itemToSave.course_type,
            price: itemToSave.price,
            liftticket_price: itemToSave.liftticket_price
          }
        })

        this.$router.push({
          name: 'CourseStep02',
          params: {first_name: this.first_name, last_name: this.last_name, birthday: this.birthday, is_familiy: this.is_familiy, editIndex: this.editIndex}
        })
      }
    },
    formatDate(dateString) {
      if(!dateString){
        return 0
      }
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

      }).format(date);
    },
    inputUserData(item){
      window.scrollTo({top: 0, behavior: "smooth"});
      this.isAlreadyRegistered = true;
      this.last_name = item.last_name;
      this.first_name = item.first_name;
      this.id = item.id;
      let bday = new Date(item.birthday);
      this.birthday = bday;

      this.is_familiy = item.is_familiy;
    },
    checkCourseData(e){
      let itemToSave = this.$store.getters.items[this.editIndex];

      if((itemToSave.participant.birthday == '' && itemToSave.participant.birthday == null) || (itemToSave.participant.first_name == '' || itemToSave.participant.last_name == '') || (itemToSave.course_end == null || itemToSave.course_start == null)){
        let conf = confirm("Wollen Sie die Buchung löschen?");

        if(conf == true){
          this.$store.dispatch('deleteItem', this.editIndex)
          if(this.$store.getters.items.length > 0){
            this.$router.push({ path: 'zusammenfassung' })
          } else {
            this.$router.push({ path: '/' })
          }
        }

      } else {

        e.preventDefault();
        this.$router.push({ path: 'zusammenfassung' })
      }

    }
  }
}
</script>
