import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from './router'
import store from './store'

import '@/styles/tailwind.css';
import '@/styles/main.scss'

import Datepicker from 'vue3-date-time-picker';


const app = createApp(App);
app.use(store).use(VueAxios, axios).use(router).mount('#app')
app.component('Datepicker', Datepicker);
