<template>
  <div class="start">
    <div class="container">
      <h1>Möchten Sie sich zu einem <strong>Kurs anmelden</strong> oder eine <strong>Privatstunde</strong> buchen?</h1>

        <div class="grid grid-cols-2 mt-40 hidden xl:grid">
          <div class="box__course box__course--course flex flex-col items-center">
            <router-link :to="{ name : 'CourseStep01', params: {editIndex: createIndex}}" v-on:click="createNewCourse">
              <img src="../assets/images/skikurs.svg" class="illustration">
            </router-link>
            <p>Zum Kurs anmelden</p>
            <router-link :to="{ name : 'CourseStep01', params: {editIndex: createIndex}}" v-on:click="createNewCourse" class="btn btn-blue flex justify-center items-center">
              <img
                src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white"></router-link>
          </div>
          <div class="box__course box__course--lesson flex flex-col items-center">
            <router-link :to="{ name : 'PrivateLessonStep01', params: {editIndex: createIndex}}" v-on:click="createNewPrivateLesson">
              <img src="../assets/images/privatstunde.svg" class="illustration">
            </router-link>
            <p>Privatstunde buchen</p>
            <router-link :to="{ name : 'PrivateLessonStep01', params: {editIndex: createIndex}}" v-on:click="createNewPrivateLesson" class="btn btn-blue flex justify-center items-center">
              <img src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white"></router-link>
          </div>
        </div>
        <div class="block xl:hidden mt-10 ">
          <img src="../assets/images/skikurs.svg" v-show="mobileType == 0" class="illustration__mobile  mx-5 ">
          <img src="../assets/images/privatstunde.svg" v-show="mobileType == 1" class="illustration__mobile mx-5">
          <div class="course__switch mt-50 mx-5">
            <div class="course__item" :class="{'active': mobileType == 0}" v-on:click="mobileType = 0">Kurs</div>
            <div class="course__item" :class="{'active': mobileType == 1}" v-on:click="mobileType = 1">Privatstunde</div>
          </div>
          <div class="flex justify-center align-center mt-8">
            <router-link :to="{ name : 'CourseStep01', params: {editIndex: createIndex}}" v-on:click="createNewCourse" class="btn btn-blue small flex justify-center items-center" v-show="mobileType == 0"><img
                src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white"></router-link>
            <router-link :to="{ name : 'PrivateLessonStep01', params: {editIndex: createIndex}}" v-on:click="createNewPrivateLesson" class="btn btn-blue small flex justify-center items-center" v-show="mobileType == 1"><img
                src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white"></router-link>
          </div>

        </div>


    </div>

  </div>
</template>

<script>

export default {
  name: 'Start',
  components: {},
  data(){
    return{
      mobileType: 0,
      createIndex: 0
    }
  },
  created(){
   let items = this.$store.getters.items;
   this.createIndex = items.length;
  },
  methods: {
    createNewCourse(){

      this.$store.dispatch('saveItem', {
        type: "application",
        participant: {
          id: 0,
          first_name: '',
          last_name: '',
          is_familiy: false,
          birthday: null,
        },
        course_start: null,
        course_end: null,
        is_liftticket_selected: false,
        skill: 0,
        course_type: 0,
        price: 0,
        liftticket_price: 0
      })
    },
    createNewPrivateLesson(){
      this.$store.dispatch('saveItem', {
        type: "private_application",
        course_type: 0,
        participants: [{
          first_name: '',
          last_name: '',
          birthday: null,
          skill: null
        }],
        dates: [],
        price: 0,
        liftticket_price: 0
      })
    }
  }
}
</script>
