<template>
  <div class="steps">
    <div class="container">
      <div class="steps__counter">
        <p>02/02<br>Kurs</p>
      </div>
      <h1>Welchen Kurs möchten Sie buchen?</h1>

      <div class="form mx-5 xl:mx-auto">
        <div class="grid grid-cols-1 gap-4 xl:grid-cols-2" v-if="foundCourses">
          <div class="form-group">
            <label for="course">Kurs</label>
            <select id="course" class="form-select" :class="fieldErrorCourse ? 'field__error' : ''" v-model="course"
                    @change="changeCourseList()">
              <option disabled selected value="0">Bitte wählen</option>
              <template v-for="course in courses">
                <option :value="{id: course.id, name: course.name}">{{ course.name }}</option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <label for="skill">Können</label>
            <select id="skill" class="form-select" :class="fieldErrorSkill ? 'field__error' : ''" v-model="skill"
                    @change="changeSkill()" :disabled="skillsDisabled">
              <option disabled selected value="0">Bitte wählen</option>

              <template v-for="skill in availableSkills">
                <option :value="{id: skill.id, name: skill.name}">{{ skill.name }} - {{ skill.short_description }}
                </option>
              </template>
            </select>
            <tooltip
                text="Man beginnt immer mit der Stufe, die bereits beherrscht wird bzw. im Lernpass dokumentiert ist."/>


          </div>
          <div class="form-group">
            <label for="startdate">Startdatum</label>
            <select id="startdate" class="form-select" :class="fieldErrorStartDate ? 'field__error' : ''"
                    v-model="startDate" @change="changeStartDate()"
                    :disabled="startDatesDisabled">
              <option disabled selected value="0">Bitte wählen</option>
              <template v-for="courseStartDate in startDates">
                <option :value="{id: courseStartDate.id, date: courseStartDate.date, course: courseStartDate}">{{
                    formatDate(courseStartDate.date)
                                                                                      }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <label for="enddate">Enddatum</label>
            <select id="enddate" class="form-select" :class="fieldErrorEndDate ? 'field__error' : ''" v-model="endDate"
                    @change="updatePrice()"
                    :disabled="endDatesDisabled">
              <option disabled selected value="0">Bitte wählen</option>
              <template v-for="courseEndDate in endDates">
                <option :value="{id: courseEndDate.id, date: courseEndDate.date}">{{
                    formatDate(courseEndDate.date)
                                                                                  }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <div v-if="!foundCourses">
          <h1 class="text-black">Keine Kurse gefunden.</h1>
        </div>
        <div class="form-group--checkbox mt-5 " v-if="foundCourses && skill !== 0
          && skillsWithLiftticket[skill.name] !== undefined">
          <input type="checkbox" id="liftTicket" class="form-checkbox" v-model="liftTicket" @change="updatePrice()">
          <label for="liftTicket">Ja, ich möchte ein Liftticket dazubuchen</label>
          <tooltip
              text="Ihr Liftticket erhalten Sie unter Vorlage der Skischulkarte an der Skiliftkasse. Es ist bei Ersteintritt für 5 Stunden gültig und kostet 11,00€ pro Tag sowie einen einmalige Pfandgebühr von 3,00€"/>
        </div>
        <div class="flex justify-between items-end">
          <div class="price" v-show="price === 0">
            <!--            -,&#45;&#45; €-->
          </div>
          <div class="price" v-show="price > 0">
            {{ priceToEuro }}
          </div>
          <div class="buttons flex flex-row  justify-end items-center">
            <router-link :to="{ name : 'CourseStep01', params:{editIndex: this.editIndex} }"
                         class="btn btn-bordered flex justify-center items-center">
              <img src="../assets/icons/bx-right-arrow-alt.svg" class="arrow arrow-left">
            </router-link>
            <div class="btn btn-blue flex justify-center items-center" v-on:click="saveItem()" v-if="foundCourses">
              <img src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white">
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>


import Tooltip from "../components/Tooltip";

export default {
  name: 'CourseStep02',
  components: {Tooltip},
  props: ['first_name', 'last_name', 'birthday', 'is_familiy', 'editIndex'],
  data() {
    return {
      course: 0,
      availableSkills: [],
      skill: 0,
      startDate: 0,
      endDate: 0,
      liftTicket: 0,
      price: 0,
      liftticket_price: 0,
      courses: null,
      startDates: null,
      endDates: null,
      skills: null,
      skillsDisabled: true,
      startDatesDisabled: true,
      endDatesDisabled: true,
      fieldErrorCourse: false,
      fieldErrorSkill: false,
      fieldErrorStartDate: false,
      fieldErrorEndDate: false,
      skillsWithLiftticket: {
        'Kurvenkeks': true,
        'Liftprofi': true,
        'Bergflitzer': true,
        'Rennfahrer': true,
        'SKI III': true,
        'SKI IV': true,
        'SKI V': true,
        'BOARD I': true
      }
    }
  },
  methods: {
    changeCourseList() {
      let idToFind = parseInt(this.course.id)
      this.endDatesDisabled = true;
      let chosenCourse = this.courses.find((item) => item.id === idToFind)
      if (chosenCourse) {
      this.skillsDisabled = false;
        this.startDates = chosenCourse.courses;
        this.startDatesDisabled = false;
        this.skills = chosenCourse.skills;
        this.availableSkills = this.skills;
      }


      this.skill = 0
      this.startDate = 0
      this.endDate = 0
      this.price = 0


    },
    changeSkill() {
      this.skill !== 0 ? this.fieldErrorSkill = false : this.fieldErrorSkill = true
    },
    changeStartDate() {
      this.endDate = 0
      this.price = 0
      if (this.startDate.id !== undefined) {
        let dateToFind = parseInt(this.startDate.id);

        this.availableSkills = this.startDate.course.skills;

        const foundSkill = this.availableSkills.filter((item) => {
          return item.id === this.skill.id;
        });

        if(foundSkill.length === 0){
          this.skill = 0;
        }

        let api = process.env.VUE_APP_COURSE_API + '/courses/' + dateToFind + '/end'

        this.axios.get(api).then((response) => {

          this.endDates = response.data
          this.endDatesDisabled = false
        })
      }
    },
    updatePrice() {
      let familyMembers = this.$store.getters.familiyMembersCount;
      if (this.startDate && this.endDate && this.skill) {
        let api = process.env.VUE_APP_COURSE_API + '/course_prices/course_start/' + this.startDate.id + '/course_end/' + this.endDate.id + '/course_skill/' + this.skill.id + '/familiy_members_count/' + familyMembers

        this.axios.get(api).then((response) => {
          this.price = parseFloat(response.data.price);
          this.liftticket_price = (this.liftTicket && response.data.can_skill_have_liftticket)
              ? response.data.liftticket_price
              : 0;
        })
      }
    },
    saveItem() {
      this.course === 0 ? this.fieldErrorCourse = true : this.fieldErrorCourse = false;
      this.skill === 0 ? this.fieldErrorSkill = true : this.fieldErrorSkill = false;
      this.startDate === 0 ? this.fieldErrorStartDate = true : this.fieldErrorStartDate = false;
      this.endDate === 0 ? this.fieldErrorEndDate = true : this.fieldErrorEndDate = false;

      if (!this.fieldErrorCourse && !this.fieldErrorSkill && !this.fieldErrorStartDate && !this.fieldErrorEndDate) {

        if (this.editIndex) {
          this.$store.dispatch('editItem', {
            index: this.editIndex,
            item: {
              type: "application",
              participant: {
                id: 0,
                first_name: this.first_name,
                last_name: this.last_name,
                is_familiy: this.is_familiy,
                birthday: this.birthday,
              },
              course_start: this.startDate,
              course_end: this.endDate,
              is_liftticket_selected: Boolean(this.liftTicket),
              skill: this.skill,
              course_type: this.course,
              price: this.price,
              liftticket_price: this.liftticket_price
            }
          })
        } else {
          this.$store.dispatch('saveItem', {
            type: "application",
            participant: {
              id: 0,
              first_name: this.first_name,
              last_name: this.last_name,
              is_familiy: this.is_familiy,
              birthday: this.birthday,
            },
            course_start: this.startDate,
            course_end: this.endDate,
            is_liftticket_selected: Boolean(this.liftTicket),
            skill: this.skill,
            course_type: this.course,
            price: this.price,
            liftticket_price: this.liftticket_price
          })
        }

        this.applyDiscount();

        // route to summary page
        this.$router.push({name: 'Summary'})
      }
    },
    applyDiscount() {
      let items = this.$store.getters.items;
      let familyMembers = this.$store.getters.familiyMembersCount;

      for (let i = 0; i < items.length; i++) {
        if (items[i].type == 'application') {

          let api = process.env.VUE_APP_COURSE_API + '/course_prices/course_start/' + items[i].course_start.id + '/course_end/' + items[i].course_end.id + '/course_skill/' + items[i].skill.id + '/familiy_members_count/' + familyMembers;

          this.axios.get(api).then((response) => {
            items[i].price = parseFloat(response.data.price);
          })
        }
      }
    },
    formatDate(dateString) {
      if (!dateString) {
        return 0
      }
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

      }).format(date);
    },

  },
  mounted() {
    let birthday = "";
    if (this.editIndex) {
      const item = this.$store.getters.items[this.editIndex];
      birthday = item.participant.birthday;
    }
    let api = process.env.VUE_APP_COURSE_API + '/course_types/birthday/' + birthday

    this.axios.get(api).then((response) => {
      this.courses = response.data;

      if (this.editIndex) {
        const item = this.$store.getters.items[this.editIndex];
        this.course = item.course_type;
        if (!this.course) {
          this.course = 0;
        }
        this.changeCourseList();

        this.skill = item.skill;

        let startDate = item.course_start;
        this.startDate = startDate;
        this.changeStartDate();

        let endDate = item.course_end;
        this.endDate = endDate;
        this.updatePrice();

        this.liftTicket = item.liftTicket;
      }
    })


  },
  computed: {
    priceToEuro() {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(this.price + this.liftticket_price);
    },
    foundCourses() {
      let out = false;
      if (Array.isArray(this.courses)) {

        if (this.courses.length > 0) {
          out = true
        }
      }
      return out;
    }
  }
}
</script>
