import { createRouter, createWebHashHistory } from 'vue-router'
import Start from '../views/Start.vue'
import Dashboard from '../views/Dashboard.vue'
import PrivateLessonStep01 from '../views/PrivateLesson_Step_01.vue'
import PrivateLessonStep02 from '../views/PrivateLesson_Step_02.vue'
import PrivateLessonStep03 from '../views/PrivateLesson_Step_03.vue'
import CourseStep01 from '../views/Course_Step_01.vue'
import CourseStep02 from '../views/Course_Step_02.vue'
import Summary from '../views/Summary.vue'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/privatstunde-disziplin',
    name: 'PrivateLessonStep01',
    component: PrivateLessonStep01,
    props: true
  },
  {
    path: '/privatstunde-uhrzeiten',
    name: 'PrivateLessonStep02',
    component: PrivateLessonStep02,
    props: true
  },
  {
    path: '/privatstunde-teilnehmer',
    name: 'PrivateLessonStep03',
    component: PrivateLessonStep03,
    props: true
  },
  {
    path: '/kurs-teilnehmer',
    name: 'CourseStep01',
    component: CourseStep01,
    props: true
  },
  {
    path: '/kurs-auswahl',
    name: 'CourseStep02',
    component: CourseStep02,
    props: true
  },
  {
    path: '/zusammenfassung',
    name: 'Summary',
    component: Summary,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
