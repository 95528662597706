<template>
  <main>
    <div class="close__booking" v-on:click="closeBooking"></div>
    <router-view/>
  </main>
  <div class="bg-mountains"></div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data(){
    return{
      isLoggedIn: false
    }
  },
  created() {
    window.addEventListener('message', this.redirectToStartScreen);

    if(process.env.NODE_ENV == 'development'){
      // If we are in dev Mode, fake a PostMessage from Window
      window.postMessage({
        event: 'INIT',
        data: {
          id: 9999999,
          first_name: "",
          last_name: "",
          participants: [
            {
              id: 1232,
              first_name: "Vorname",
              last_name: "Nachname",
              is_familiy: true,
              birthday: " 1970-01-01"
            }
          ],
          items: [ {
            "type": "application",
            "participant": {
              id: 1232,
              first_name: "Vorname",
              last_name: "Nachname",
              is_familiy: true,
              birthday: " 1970-01-01"
            },
            "course_start": {},
            "course_end": {},
            "is_liftticket_selected": true,
            "liftticket_price": 10,
            "skill": {},
            "course_type": {},
            "price": 1232.12
          },
            {
              "type": "private_application",
              "course_type": "Ski Alpin",
              "price": 99.50,
              "dates": [
                {
                  "date": "2021-12-11",
                  "from": "09:00",
                  "to": "16:00"
                },
                {
                  "date": "2021-12-11",
                  "from": "10:00",
                  "to": "12:00"
                }
              ],
              "participants": [
                {
                  "first_name": "Vorname",
                  "last_name": "Nachname",
                  "birthday": "1970-01-01",
                  "skill": "Fortgeschritten"
                },
                {
                  "first_name": "Vorname 1",
                  "last_name": "Nachname 1",
                  "birthday": "1970-01-01",
                  "skill": "Fortgeschritten"
                },
                {
                  "first_name": "Vorname 2",
                  "last_name": "Nachname 2",
                  "birthday": "1970-01-01",
                  "skill": "Experte"
                }
              ]
            }

          ]
        }
      })
    }

  },
  methods: {
    redirectToStartScreen() {
      let data = event.data;

      if(data.event === 'INIT'){
        this.isLoggedIn = true;
        this.$store.dispatch('login', data.data);
        window.removeEventListener('message', this.redirectToStartScreen);
        if(data.data.items.length > 0){
          for (let i = 0; i < data.data.items.length; i++) {
            this.$store.dispatch('saveItem', data.data.items[i]);
          }
          this.$router.push({name: 'Summary'})
        } else {

          this.$router.push({name: 'Start'})
        }

      }

    },
    closeBooking(){
      let conf = confirm("Wollen Sie die Buchung beenden?");
      if(conf){
        window.parent.postMessage({
          "event": 'CLOSE'
        }, process.env.VUE_APP_ROOT_API)
      }

    }
  }
}
</script>
