<template>
  <div class="card-close" v-on:click="deleteApplication"></div>
  <div class="card-header flex justify-center">
    <img src="../assets/images/teilnehmer-privatstunde_02.svg" class="teaser teaser__big">
  </div>
  <div class="card-body smaller__table" v-if="item">
    <h4 class="font-bold">Privatstunde</h4>
    <table >
      <dl>
        <dt>Disziplin:</dt>
        <dd class="font-bold">{{ item.course_type }}</dd>
        <template v-for="(date, index) in item.dates">
          <dt>Zeitraum {{ index +1 }}:</dt>
          <dd>{{  formatDate(date.date)}}, {{date.from}} - {{date.to}} Uhr ({{timeToHours(date.from, date.to)}} h)</dd>
        </template>
        <dt>Teilnehmer:</dt>
        <dd v-if="item.participants">
          <ul class="participants__info">
            <li v-for="participant in item.participants">
              {{participant.first_name}} {{ participant.last_name}}, {{ formatDate(participant.birthday)}}, {{participant.skill}}
            </li>
          </ul>
        </dd>
        <template v-if="item.price">


          <dt class="price">Preis:</dt>
          <dd class="price"><strong>{{ priceToEuro(item.price) }} </strong></dd>
        </template>
      </dl>
    </table>

    <div class="flex justify-center">
      <a  v-on:click="editItem(index)" class="link--text cursor-pointer">Privatstunde bearbeiten</a>
    </div>
  </div>
</template>

<script>
import de from "date-fns/locale/de";
import {mapState} from "vuex";

export default {
  name: 'Card_PrivateApplication',
  components: {},
  props: {
    item: Object,
    index: Number
  },
  methods: {
    priceToEuro(price){
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(price);
    },
    formatDate(dateString) {
      if(!dateString){
        return 0
      }
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('de-DE',{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

      }).format(date);
    },
    timeToHours(start, end){
      if(!start || !end){
        return 0
      }
      start = this.convertDateStringToInt(start);
      end = this.convertDateStringToInt(end);

      return end - start;
    },
    convertDateStringToInt(dateString){
      let hms = dateString.split(':');
      let out = parseInt(hms[0])

      if(hms[1] == '30'){
        out += 0.5;
      }

      return out
    },
    editItem(index){

      this.$router.push({
        name: 'PrivateLessonStep01',
        params: {editIndex: index}
      })
    },
    deleteApplication(){
      let conf = confirm("Wollen Sie die Buchung löschen?");
      if(conf){
        this.$store.dispatch('deleteItem', this.index)
      }
    },
  }
}
</script>
